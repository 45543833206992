import React from 'react';
import Link from 'gatsby-link'
import Logo from "../icons/logo-white.svg";

const Footer = (props) => {

  const languageSwicher = (link) => {
    let locale = props.locale === 'de'?'':props.locale + '/';
    return '/' + locale + (link?link:'')
  };

  return (
    <footer className="footer">
      <div className="constrain constrain--large">
        <div className="footer__inner">
          <div className="footer__inner__info">
            <div className="space--bottom">
              <p>lopezdesign</p>
              <p className='space--bottom-half'>{props.locale === 'de'?'Online Kreativagentur':'Agencia creativa online'}</p>
              <p>Cristian López - {props.locale === 'de'?'Web-Entwickler':'Desarrollador web'}</p>
              <p>E-Mail: <a href="mailto:mail@lopezdesign.de" title="mail@lopezdesign.de">mail@lopezdesign.de</a></p>
              <p>Web: <a target="_blank" rel="noreferrer" title="lopezdesign.de" href="https://www.lopezdesign.de">lopezdesign.de</a></p>
              <nav className="footer__inner__info__navigation">
                <ul>
                  <li>
                    <Link to={languageSwicher('imprint')}>{props.locale === 'de'?'Impressum':'Texto legal'}</Link>
                  </li>
                  <li>
                    <Link to={languageSwicher('privacy')}>{props.locale === 'de'?'Datenschutz':'Privacidad'}</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <Link className="logo" to={languageSwicher()} title="logo - lopezdesign"><Logo alt="logo - lopezdesign" title="logo - lopezdesign"/></Link>
          </div>
          <p className="footer__inner__after">© {new Date().getFullYear()} - lopezdesign.de</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
