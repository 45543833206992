import * as React from "react"

import Header from '../components/Header'
import Footer from '../components/Footer'
import Helmet from 'react-helmet'
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import { StaticQuery, graphql } from "gatsby"
import { IntlProvider } from 'react-intl';
import 'intl';

import "../css/style.scss"

const Layout = ({ children, location, i18nMessages, title, description }) => {
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              languages {
                defaultLangKey
                langs
              }      
            }
          }
        }
      `}
      render={data => {
        const url = location.pathname;
        const { langs, defaultLangKey } = data.site.siteMetadata.languages;
        const langKey = getCurrentLangKey(langs, defaultLangKey, url);
        const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
        const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));
        return (
            <IntlProvider
              locale={langKey}
              messages={i18nMessages}
            >
            <div className="wrapper">
              <Helmet
                htmlAttributes={{
                    lang: langKey,
                }}
                title={title + ' - lopezdesign'}
                meta={[
                  { name: 'description', content: description },
                  { name: 'keywords', content: 'lopezdesign, Web development, UI & UX Design' },
                  { name: 'author', content: 'Cristian López - lopezdesign' },
                ]}
              />
              <Helmet>
                <meta property="og:title" content={title + ' | lopezdesign'}/>
                <meta property="og:image" content={"https://lopezdesign.de/logo.png"} />
                <meta property="og:image:width" content="512" />
                <meta property="og:image:width" content="512" />
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={homeLink}/>
                <meta property="og:description" content={description}></meta>
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"></link>
                <link rel="apple-touch-icon" sizes="180x180" href="/180x180.png"></link>
              </Helmet>
              <Header langs={langsMenu} locale={langKey}/>
              <main>
                {children}
              </main>
              <Footer locale={langKey}/>
            </div>
          </IntlProvider>
        )
      }}
    />
  );
};

// Layout.propTypes = {
//   children: PropTypes.func,
// }

export default Layout
