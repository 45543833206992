import React from 'react'
import Grid from "./Grid"

const Block = (props) => {

  return(
    <section className={'block' + (props.color?' bg--' + props.color:'')}> 
      <div className="constrain constrain--large">
        <Grid left={props.left} right={props.right} />
      </div>
    </section>
  )
};

export default Block