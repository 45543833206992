import * as React from "react"

const Grid = (props) => {
  return(
    <div className="grid grid--middle">
      {props.left}
      {props.right}
    </div>
  )
};

export default Grid