import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
// import { FormattedMessage } from 'react-intl';

const SelectLanguage = (props) => {
  const links = props.langs.map(lang =>
    <Link title={lang.langKey} to={lang.link} key={lang.langKey} style={{
      color: 'white'
    }}>
      <span selected={lang.selected}>
        {lang.langKey}
      </span>
    </Link>
  );

  return (
    <nav className="language">
      {/* <FormattedMessage id="selectLanguage" /> */}
      {links}
    </nav>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array
};

export default SelectLanguage;
